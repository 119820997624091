import './Home.css';
import { validateSite } from './helpers'
import React, { useState } from 'react';

const Home = () => {
  const [siteId, setSiteId] = useState('');
  const [error, setError] = useState('');

  const submitForm = (e) => {
    e.preventDefault();
    if (validateSite(siteId)) {
      window.location.href = 'https://' + siteId + '.ezyvet.com/'+window.location.search;
    } else {
      setError("Invalid Site prefix. Enter the first part of your ezyVet website address.")
    }
  }

  const handleChange = (value) => {
    setSiteId(value);
  }

  return (
    <div>
      <form onSubmit={submitForm} className="mainForm">
        <img className="ezyLogo" src="ezyvet.png" alt="ezyVet Logo" />
        <div className="siteText">We could not detect your ezyVet site, Please enter your URL to continue</div>
        { error && <div className="errorMessage">
          {error}
          </div>
        }
        <input className="siteField" placeholder="Site prefix" onChange={(e) => handleChange(e.target.value)} /> .ezyvet.com
        <button className="submitButton"  type="submit">
          Go
        </button>
      </form>
    </div>
  )
}

export default Home;