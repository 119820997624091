// import axios from 'axios';
import Cookies from 'js-cookie';

export const validateCookie = () => {
  // Global cookie name
  let cookieName = 'ezyVetSite';

  // Fetch cookie
  let siteId = Cookies.get(cookieName)
  
  // If set, validate and either redirect or remove cookie
  if (siteId !== undefined) {
    if (validateSite(siteId)) {
      window.location.href = 'https://' + siteId + '.ezyvet.com'+window.location.search;
      return true;
    } else {
      Cookies.remove(cookieName)
      return false;
    }
  }

  return false;
}

// validateSite - Returns bool
export const validateSite = (siteId) => {
    // Check alphanumeric chars and/or .trial|.stg
    if (siteId.match(/^([a-zA-Z0-9]+)((\.trial|\.stg)?)$/)) {
      return true;
    }

    return false;

    // TODO: Check site exists before redirecting - CORS issues currently
    // return axios.get('https://' + siteId + '.ezyvet.com/', { crossdomain: true })
    //     .then((data) => {
    //         console.log(data);
    //         // return data.satus;
    //     }).catch((error) => {
    //         console.log(error);
    //         return false;
    //     });
};

export const setBackgroundImage = () => {
  const backgroundImages = [
    "cat.png",
    "cow.png",
    "bird.png",
  ];

  let randImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
  return 'https://assets.ezyvet.com/media/images/login/' + randImage;
};
