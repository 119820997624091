import './App.css';
import Home from './Home.js';
import { validateCookie, setBackgroundImage } from './helpers'
import React, { useEffect, useState } from 'react';
// import Cookies from 'js-cookie';


const App = () => {
  const [loading, setLoading] = useState(true);

  // Used for testing locally
  // Cookies.set('ezyVetSite', 'demo30'); 

  // Set random main background image
  let backgroundURL = setBackgroundImage();
  
  // Check cookie and redirect or render page
  useEffect(() => {
    if (!validateCookie()) {
      setLoading(false);
    }
  }, [])

  if (loading) {
    return null;
  }

  // Remove loading spinner from html
  const el = document.querySelector(".loader-container");
  if (el) {
    el.remove();
  }

  // Render page if we don't have a valid cookie
  return (
    <div className="app" style={{
      backgroundImage: `url(${backgroundURL})`
    }}>
      <Home />
    </div>
  );
}

export default App;
